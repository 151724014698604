import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FormattedTitle from "../components/common/FormattedTitle"
import Pagination from "../components/common/Pagination"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"

const ReleasesIndex = props => {
  const { data, pageContext } = props
  return (
    <Layout>
      <h1>
        All <span className="purple-underline">Releases</span>
      </h1>
      <div className="position-relative" style={{ zIndex: "2" }}>
        {data.drupal.nodeQuery.entities.map((entity, index) => {
          const artistName = entity?.artist?.entity?.entityLabel || "Unknown"
          const thumbnail = getImage(entity?.thumbnail || {})
          const entityUrl = entity?.entityUrl?.path
          return (
            <div className="row py-4 py-md-4 gy-4 gy-md-0" key={index}>
              <div className="col col-12 col-md-6">
                {thumbnail && (
                  <div>
                    <Link to={entityUrl}>
                      <GatsbyImage
                        alt={entity?.entityLabel || ""}
                        image={thumbnail}
                        imgClassName="preview"
                        loading="eager"
                        className="rounded-4"
                      />
                    </Link>
                  </div>
                )}
              </div>
              <div className="col col-12 col-md-6">
                <h3>
                  <Link to={entityUrl} className="white">
                    {entity?.entityLabel}
                  </Link>
                </h3>
                <h5>
                  <FormattedTitle>{artistName}</FormattedTitle>
                </h5>
                <div
                  dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
                />
                <div
                  className="embed-animation"
                  dangerouslySetInnerHTML={{ __html: entity?.embedCode }}
                />
              </div>
            </div>
          )
        })}
      </div>
      <div className="py-4">
        <Pagination pageContext={{ ...pageContext, pagePrefix: "releases" }} />
      </div>
      <div className="py-4" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/" className="white">
          Go back to the homepage
        </Link>
      </div>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    drupal {
      nodeQuery(
        offset: $skip
        limit: $limit
        filter: {
          conditions: [
            { field: "status", value: "1", operator: EQUAL }
            { field: "type", value: "release", operator: EQUAL }
          ]
        }
        sort: { field: "field_release_date", direction: DESC }
      ) {
        entities {
          entityId
          entityLabel
          entityUrl {
            path
          }
          ... on Default_NodeRelease {
            image: fieldImage {
              url
              id: targetId
            }
            body {
              value: processed
              summary: summaryProcessed
            }
            thumbnail: gatsbyImageRectThumbnail {
              childImageSharp {
                gatsbyImageData(width: 480, height: 480)
              }
            }
            artist: fieldArtist {
              entity {
                entityId
                entityLabel
              }
            }
            url: fieldUrl {
              value
              processed
            }
            releaseDate: fieldReleaseDate {
              value
              date
            }
            embedCode: fieldEmbedCode
          }
        }
      }
    }
  }
`

export default ReleasesIndex
